@import "./../../../../assets/css/variables.scss";

.productsListWrap {
  margin-bottom: 25px;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin: 0;
    padding-bottom: 40px;
  }
  &:last-of-type,
  &:last-child {
    margin-bottom: 0;
  }
  .titleProductsList {
    margin: 0 0 16px 0;
    padding: 9px 8px;
    border-bottom: 2px solid #332E20;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #332E20;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin: 0;

      display: none;
    }
  }
}

.productsList {
  border-bottom: 4px solid rgba(196, 196, 196, 0.125);;
}

.productsItem {
  padding: 12px 15px 12px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #332E20;
  @media screen and (max-width: $breakpoint-mob-max) {
    padding-right: 0;
    border-top: 2px solid rgba(196, 196, 196, 0.125);
    border-bottom: 2px solid rgba(196, 196, 196, 0.125);
  }
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
  .imgWrap {
    width: 90px;
    height: 90px;
    margin-right: 15px;
    @media screen and (max-width: $breakpoint-IPhone-max) {
      width: 60px;
      height: 60px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .textContent {
    flex: 1 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .descrWrap {
    width: auto;
    margin: auto;
    padding-right: 10px;
    @media screen and (max-width: $breakpoint-mob-max) {
      padding-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
  .productTitle {
    margin: 0 0 8px 0;
    font-weight: 700;
  }
  .productInfoRow {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    line-height: 1.3;
    p {
      margin: 0;
      &:first-child {
        margin-right: 5px;
        width: 50px;
      }
    }
  }
  .productId {
    margin: 0 0 8px 0;
    font-weight: 400;
    font-size: 12px;
  }
  .productDescr {
    margin: 0;
    font-weight: 250;
    @media screen and (max-width: $breakpoint-IPhone-max) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .countAndPrice {
    display: flex;
    align-items: center;
  }
  .countWrap {
    width: 100px;
    // flex: 100px 1 1;
    font-weight: 700;
    text-align: center;
    @media screen and (max-width: $breakpoint-mob-max) {
      text-align: left;
      min-width: 50px;
      max-width: 100px;
      width: auto;
      margin-right: 16px;
    }
  }
  .priceWrap {
    width: 95px;
    // flex: 95px 1 1;
    font-weight: 700;
    text-align: right;
    @media screen and (max-width: $breakpoint-mob-max) {
      text-align: left;
    }
    > span {
      @media screen and (max-width: $breakpoint-mob-max) {
        font-weight: 400;
      }
    }
  }
}