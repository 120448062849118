.inputNumberSize {
  margin-bottom: 16px;
  div[class*="btnInputNumber"] {
    opacity: 1 !important;
  }
  //wrapperInputNumber
  div[class*="wrapperInputNumber"] {
    box-shadow: none;
  }
  input[class*="inputStyle"],
  input {
    &:focus-visible {
      outline: none;
    }
  }
}
.inputNumberSizeLabel {
  margin:  0 0 8px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}