@import "./../../assets/css/variables.scss";

.mobHeader {
  display: none;
  grid-area: header;
  background-color: #fed330;
  @media screen and (max-width: $breakpoint-mob-max) {
    display: block;
  }
}

.pageGrid {
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-areas: "sidebar content";
  grid-gap: 0;
  width: 100vw;
  height: 100vh;
  font-family: "ProximaNova", sans-serif;
  @media screen and (max-width: $breakpoint-mob-max) {
    grid-template-columns: 1fr;
    grid-template-rows: 40px 1fr 40px;
    grid-template-areas:
      "header"
      "content"
      "sidebar";
  }
}

.mainSidebar {
  grid-area: sidebar;
  padding: 20px 20px 16px;
  background-color: var(--brand-color);
  position: relative;
}

.sceneContent {
  position: relative;
  grid-area: content;
  .sceneLoader {
    position: absolute;
    z-index: 105;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #ffffff, $alpha: .9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.infoSelectModify {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  max-width: 370px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #E9ECEF;
  border-radius: 2px;
  > svg {
    min-width: 24px;
    height: auto;
    flex: 1;
  }
  .text {
    margin: 0;
    color: #31344B;
    font-size: 16px;
    font-weight: 400;
    line-height: 125%; /* 14px */
  }
}