@import "./../../../assets/css/variables.scss";

.sceneInfoText {
  position: absolute;
  right: 100px;
  bottom: 24px;
  padding-left: 40px;
  width: auto;
  max-width: 50%;
  @media (max-width: 1350px) {
    padding-left: 20px;
    max-width: calc(100% - 437px - 100px);
  }
  p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  // &.active {
  //   left: 437px;
  // }
 
}