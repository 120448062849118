.subMenu2DWrap {
  width: 226px;
  padding: 16px 24px 32px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  .close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;
  }
}