@import "./../../../assets/css/variables.scss";

.sceneControlsTop {
  position: absolute;
  top: 27px;
  right: 27px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $breakpoint-mob-max) {
    top: 12px;
    right: 8px;
    left: 8px;
    justify-content: flex-end;
  }
  > * {
    margin-right: 16px;
    &:first-child {
      // margin-right: 26px;
      @media screen and (max-width: $breakpoint-mob-max) {
        margin-right: auto;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  button {
    &:last-child,
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.wallsHeightInputWrap {
  div[class*="inputNumberSize"] {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  div[class*="inputNumberSizeLabel"] {
    margin-right: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  input[class*="inputStyle"] {
    height: 38px;
  }

  input {
    height: 38px;
  }

  div[class*="wrapperInputNumber"] {
    max-width: 140px;
  }

  div[class*="wrapInputFeetAndInches"] {
    max-width: 140px;
    border: 1px solid #77777B;
    height: 40px;
  }
}

.wallsMeasuremenWrap {
  div[class*="wrap"] {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  [class*="title"] {
    margin-right: 15px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  div[class*="wrap"] label {
    font-size: 12px;
    line-height: 15px;
  }
}

.infoClosedContur {
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  max-width: 310px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #E9ECEF;
  > svg {
    min-width: 24px;
    height: auto;
    flex: 1;
  }
  .text {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }
}