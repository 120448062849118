.modalContinue2D {
  position: relative;
  width: 100%;
  max-width: 944px;
  padding: 63px 135px;
  background-color: #fff;
  .title {
    margin: 0 0 32px 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    color: #000000;
    text-align: center;
  }
  .noteText {
    margin: 0 0 32px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  .btnsWrap {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 540px;
    margin:  0 auto;
    .col {
      width: 50%;
    }
  }
}