@import "./../../../../assets/css/variables.scss";

.distributorsLinks {
  display: flex;
  column-gap: 16px;
  margin-bottom: 8px;
  @media screen and (max-width: $breakpoint-mob-max) {
    margin-top: 8px;
  }
  a {
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #3C93E4;
    @media screen and (max-width: $breakpoint-xl-max) {
      line-height: 1;
    }
    &:hover {
      text-decoration: none;
    }
  }
}