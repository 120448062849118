@import "./../../../assets/css/variables.scss";

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(229, 229, 229, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $breakpoint-mob-max) {
    max-height: calc(100vh - 50px);
    padding: 25px 10px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: "ProximaNova", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 24px;
}

.loader {
  position: relative;
  width: 140px;
  height: 140px;
  background: #ffffff;
  border-radius: 20px;
}
.rotater {
  position: relative;
  width: 100%;
  height: 100%;
  animation: move 5s infinite ease;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
.elem {
  position: absolute;
  width: 50px;
  height: 50px;
  background: var(--brand-color);
  border-radius: 10px;

  &_1 {
    top: 16px;
    left: 16px;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation: infiniteAnimationSmall 3s ease infinite;
  }
  &_2 {
    top: 16px;
    right: 16px;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation: infiniteAnimationLong 3s ease infinite;
  }
  &_3 {
    right: 16px;
    bottom: 16px;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation: infiniteAnimationSmall 3s ease infinite;
  }
  &_4 {
    bottom: 16px;
    left: 16px;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation: infiniteAnimationLong 3s ease infinite;
  }
}

@keyframes move {
  0% {
    transform: rotate(0deg);
  }

  15% {
    transform: rotate(95deg);
  }
  20% {
    transform: rotate(90deg);
  }

  35% {
    transform: rotate(185deg);
  }
  40% {
    transform: rotate(180deg);
  }

  55% {
    transform: rotate(275deg);
  }
  60% {
    transform: rotate(270deg);
  }

  75% {
    transform: rotate(365deg);
  }
  80% {
    transform: rotate(360deg);
  }

  95% {
    transform: rotate(455deg);
  }
  100% {
    transform: rotate(450deg);
  }
}

@keyframes infiniteAnimationSmall {
  0% {
    height: 50px;
  }
  10% {
    height: 50px;
  }
  28% {
    height: 20px;
  }
  38% {
    height: 20px;
  }
  40% {
    opacity: 1;
  }
  66% {
    opacity: 0;
    height: 20px;
  }
  85% {
    opacity: 1;
    height: 50px;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
}

@keyframes infiniteAnimationLong {
  0% {
    height: 50px;
  }
  10% {
    height: 50px;
  }
  28% {
    height: 80px;
  }
  38% {
    height: 80px;
  }
  56% {
    height: 108px;
  }
  66% {
    height: 108px;
  }
  85% {
    height: 50px;
  }
  100% {
    height: 50px;
  }
}
