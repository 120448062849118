@import "./../../../assets/css/variables.scss";

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  background: none;
  transition: all .2s ease-in-out;
  font-family: "ProximaNova", sans-serif;

  &.disabled {
    opacity: .2;
    cursor: default;
    pointer-events: none;
  }

  &_small {
    padding: 2px 16px;
  }

  &_middle {
    padding: 7px 20px;
    font-size: 14px;
    line-height: 17px;
  }

  &_big {
    padding: 2px 26px;
    height: 40px;
    @media screen and (max-width: $breakpoint-mob-max) {
      padding: 2px 16px;
    }
  }

  &_bigSuper {
    padding: 2px 95px;
    height: 56px;
    @media screen and (max-width: $breakpoint-mob-max) {
      padding: 2px 45px;
      height: 46px;
    }
  }

  &_fullWidth {
    width: 100%;
  }

  &_blueDark {
    background: var(--brand-color);
    border: 1px solid var(--brand-color);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    &:hover {
      color: #141E34;
      background: var(--brand-color-hover);
    }
    &.btn_bigSuper {
      // font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      @media screen and (max-width: $breakpoint-mob-max) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &_white {
    background: #FFFFFF;
    border: 1px solid var(--brand-color);
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--brand-color);
    &:hover {
      background: var(--brand-color-hover);
      color: #141E34;
    }
    &.btn_bigSuper {
      // font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      @media screen and (max-width: $breakpoint-mob-max) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  &_backMenu {
    margin-bottom: 27px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    font-family: "ProximaNova", sans-serif;
    font-weight: 250;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    @media screen and (max-width: $breakpoint-mob-max) {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 19px;
    }
    > svg {
      transition: all .2s ease-in-out;
    }
    &:hover {
      > svg {
        transform: translateX(-3px);
      }
    }
  }

  &_icon {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    width: 30px;
    min-height: 30px;
    // background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    padding: 3px;
    &.active, &:hover {
      background: var(--brand-color-hover);
      // svg {
      //   path {
      //     fill: #FFFFFF;
      //     stroke: #FFFFFF;
      //   }
      // }
      // span {
      //   font-weight: 600;
      //   color: #FFFFFF;
      // }
    }
    svg {
      width: 100%;
      height: auto;
      path {
        transition: all .2s ease-in-out;
      }
    }
    span {
      font-weight: 600;
      transition: all .2s ease-in-out;
    }
  }

  &_redLight {
    background: #EDAFAF;
    border-radius: 2px;
  }

  &_bold {
    font-weight: 700;
  }

}