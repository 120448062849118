.sceneControlsGroup2D {
  position: absolute;
  // right: 200px;
  // bottom: 24px;
  padding: 16px 24px;
  // width: max-content;
  // width: 350px;
  max-width: 315px;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  transform: translateX(-50%);
  z-index: 999;

  span.triangle {
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 14px solid #ffffff;
  }
  span.triangle:before {
    content: "";
    width: 19px;
    display: block;
    position: absolute;
    top: 6px;
    left: -17px;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    transform: rotate(-45deg);
  }
  span.triangle:after {
    content: "";
    width: 21px;
    display: block;
    position: absolute;
    top: 6px;
    left: -4px;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    transform: rotate(45deg);
  }

  .groupAddBtns,
  .groupDeleteBtns {
    padding: 16px 0;
    border-top: 2px solid rgba(0, 0, 0, 0.15);
    > * {
      padding-top: 6px;
      padding-bottom: 6px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > * {
    &:last-child,
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .groupAddBtns {
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  }
}